<template>
  <div>
    <div v-show="account">
      <section>
        <div class="columns is-mobile mt-5" id="greenpurpleRow">
          <div class="
                      column
                      is-half is-three-fifths-tablet is-full-mobile
                      left-column
                    ">
            <div class="pb-4">
              <div v-if="canMint">
                <div class="columns is-centered">
                  <div class="column">
                    <div v-if="!ipfsFile && !ipfsMetadata">
                      <div>
                        <b-field label="Choose a file for minting *"> </b-field>
                        <div v-if="fileToMint.name">
                          <div class="
                                      is-flex
                                      is-justify-content-space-between
                                      is-align-items-center
                                    ">
                            File:
                            <small><u>{{ fileToMint.name }}</u></small>

                            <div>
                              <b-button type="small" style="background-color: white !important" @click="fileToMint = {}">
                                Clear
                              </b-button>
                              <b-button label="Preview" type="small button_use_cases"
                                @click="isImageModalActive = true" />
                            </div>
                            <b-modal v-model="isImageModalActive">
                              <p class="image">
                                <img :src="preview" />
                              </p>
                            </b-modal>
                          </div>
                        </div>

                        <b-field v-if="!fileToMint.name" class="size-large" custom-class="is-large">
                          <b-upload v-model="fileToMint" expanded drag-drop>
                            <section class="section">
                              <div class="content has-text-centered">
                                <p>Drop your files here or click to upload</p>
                              </div>
                            </section>
                          </b-upload>
                        </b-field>
                      </div>
                      <div class="mt-5">
                        <b-field label="Name *"> </b-field>
                        <b-input placeholder="Type a name" v-model="name"></b-input>
                      </div>
                      <div class="mt-5">
                        <b-field label="Description"> </b-field>
                        <b-input maxlength="200" v-model="description" placeholder="Type a description of your NFT"
                          type="textarea"></b-input>
                      </div>
                      <div v-if="standardContract.toString() === '1155'" class="mb-5">
                        <b-field label="Amount *"> </b-field>
                        <b-input v-model="amount" placeholder="Type an amount" type="number"></b-input>
                      </div>
                    </div>

                    <div v-if="ipfsFile" class="mt-5" style="
                                padding: 20px;
                                text-align: center !important;
                                font-size: 22px;
                              ">
                      <h3 class="mb-3"><b>Mint your Tokens</b></h3>
                      <h4>
                        Your metadata are ready!<br />
                        Please verify it on the link below.
                      </h4>
                      <hr />
                      <b><a :href="API_URL + '/ipfs/' + ipfsFile" target="_blank">{{ ipfsFile }}</a></b>
                      <hr />
                      <p>
                        Click the "MINT" button when you're ready to mint your
                        Tokens.
                      </p>
                    </div>
                    <div class="has-text-centered">
                      <b-button v-if="
                        ipfsFile &&
                        ipfsMetadata &&
                        !isMinting &&
                        !isPrepareMinting
                      " type="fill" style="min-width: 200px" v-on:click="mint">MINT!</b-button>
                      <b-button v-if="!ipfsFile && !ipfsMetadata && !isUploadingIPFS" v-on:click="uploadFile"
                        type="fill">Prepare metadata</b-button>
                    </div>
                    <!--
                    <div v-if="fileToMint.name">
                      Selected file: <b>{{ fileToMint.name }}</b>
                    </div> -->
                    <div style="text-align: center" v-if="isPrepareMinting">
                      Preparing Mint, please wait...
                    </div>
                    <div style="text-align: center" v-if="isMinting">
                      Minting NFT, please wait...
                    </div>
                    <div style="text-align: center" v-if="isUploadingIPFS">
                      Uploading metadata to IPFS, please wait...
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!canMint">Sorry, you can't mint on this contract, please choose another one.</div>
            </div>
          </div>
          <div class="column">
            <div class="has-text-start right-column">
              <div class="b-bottom">
                <div class="px-4 mb-5">
                  <div class="mt-5 card card-q d-block">
                    <h3><strong>Control Panel</strong></h3>
                    <p>
                      Wallet in use: 
                      <a :href="EXPLORER_URL + '/address/' + account" target="_blank">{{ account }}</a>
                    </p>
                    <div v-if="account && isContractChecked">
                      <b-field label="Select contract">
                        <b-select v-model="contractAddress" placeholder="Select a contract">
                          <option v-for="option in availableContracts" :value="option.address" :key="option.address">
                            [{{ option.standard }}] {{ option.address }}
                          </option>
                        </b-select>
                      </b-field>
                      <b-button v-if="false" class="mt-4" @click="changeContract()">Change Contract</b-button>
                    </div>
                    <hr />
                    <b-button v-if="false" class="mt-4" type="button violet" @click="changeContract()">Change
                      Contract</b-button>
                    <!-- As of now this link will birng user to all minted and
                      owned NFTs, thus may be better to change the button label
                      from "Minted NFT" to "Your NFTs"
                    -->
                    <div class="text-center">
                      <a :href="'/nfts/' + account">
                        <button class="button violet">Your NFTs</button>
                      </a>
                      <a :href="'/profile/' + account">
                        <button class="button violet">Public Gallery</button>
                      </a>
                    </div>
                  </div>
                  <div v-if="preview && show" class="img_container mt-4">
                    <div class="card card-q">
                      <img :src="preview" />
                    </div>
                  </div>

                  <div class="fake-browser">
                    <header class="fake-browser-header">
                      <div class="action-btns">
                        <span></span>
                        <span></span> <span></span>
                      </div>
                      <small><strong>Minting Console</strong></small>
                    </header>
                    <section class="fake-window-body">
                      <div style="
                                  list-style-type: none;
                                  font-family: 'Roboto Mono', monospace;
                                  font-size: 14px;
                                  line-height: 25px;
                                  padding-left: 5px;
                                  background-color: #262951 !important;
                                  color: #e7e9db !important;
                                " id="printLog"></div>
                      <span class="blinking-cursor"></span>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div v-if="!account" class="
                is-flex is-justify-content-center is-align-items-center is-full-mobile
              " style="height: 75vh">
      <div class="has-text-centered mt-5">
        <h1>
          <strong>Welcome to Quadrans NFT Creator</strong><br />
          Please connect your Metamask wallet first,<br />
          a pop-up window should be open automatically,<br />
          click on the Fox icon in your browser or the below button to
          proceed.<br /><br />
        </h1>
        <b-button type="violet" v-on:click="connect">CONNECT METAMASK</b-button>
      </div>
    </div>
  </div>
</template>

<script>
var Web3 = require("web3");
const ABI_721 = require("../util/abi721.json");
const ABI_1155 = require("../util/abi1155.json");
const axios = require("axios");
const FormData = require("form-data");
// Add whitelists here
// Mainnet
const wl_0x970 = require("../whitelists/0x970e7BC2bea44f3796d9D895496e2C50a3E61652.json");
const wl_0xd36 = require("../whitelists/0xD36842082A4758C26E1937fA080Ad05167c98e26.json");
const wl_0xda8 = require("../whitelists/0xdA866C790Cf91d9fC059C1d62315E9C4455D6F70.json");
// Testnet
const wl_0x804 = require("../whitelists/0x804279455957bF0916efD0c2e24B22F8E5807330.json");
const wl_0x348 = require("../whitelists/0x34822e1Bf1288E5568Db2C5784C8134C24B8c49E.json");

export default {
  name: "Mint",
  data() {
    return {
      web3: new Web3(window.ethereum),
      contractAddress: "",
      account: "",
      isContractChecked: "",
      standardContract: "",
      contract: {},
      signature: "",
      code: "",
      fileToMint: {},
      preview: "",
      isUploadingIPFS: false,
      isUploadingMetadata: false,
      isMinting: false,
      isPrepareMinting: false,
      name: "",
      show: false,
      ipfsFile: "",
      ipfsMetadata: "",
      description: "",
      axios: axios,
      amount: "",
      isImageModalActive: false,
      isCardModalActive: false,
      availableContracts: [],
      canMint: false,
      whitelists: {
        wl_0x970,
        wl_0xd36,
        wl_0x348,
        wl_0x804,
        wl_0xda8
      },
      API_URL: process.env.VUE_APP_IPFS_ENDPOINT,
      EXPLORER_URL: process.env.VUE_APP_EXPLORER_URL,
    };
  },
  mounted() {
    this.connect();
  },
  watch: {
    fileToMint() {
      try {
        this.preview = URL.createObjectURL(this.fileToMint);
      } catch (e) {
        this.preview = "";
      }
    },
    contractAddress() {
      const app = this
      const wl = this.whitelists["wl_" + this.contractAddress.substr(0, 5).toLowerCase()];
      console.log("WHITELIST", wl);
      if (wl !== undefined && (wl.indexOf(this.account) !== -1 || wl['*'] !== -1)) {
        this.canMint = true;
      }
      for (let j in app.availableContracts) {
        if (app.availableContracts[j].address === app.contractAddress) {
          app.standardContract = app.availableContracts[j].standard
        }
      }
    },
  },
  methods: {
    printLog(message, value = "") {
      console.log(message, value);
      document.getElementById("printLog").innerHTML =
        document.getElementById("printLog").innerHTML +
        "<div class='console-margin'>" +
        message +
        " " +
        value;
      this.scrollToEnd();
    },
    async connect() {
      const app = this;
      console.log("Connecting..")
      window.ethereum.enable();
      window.ethereum.on("accountsChanged", (accounts) => {
        app.connect();
      });
      try {
        let accounts = await app.web3.eth.getAccounts();
        app.account = accounts[0];
        console.log("Found account:", accounts[0])
        const netId = await app.web3.eth.net.getId();
        console.log("Network id:", netId);
        app.availableContracts = []
        if (parseInt(netId) === parseInt(process.env.VUE_APP_NETWORK_ID)) {
          const available721 =
            process.env.VUE_APP_721_CONTRACT_ADDRESSES.split(",");
          const available1155 =
            process.env.VUE_APP_1155_CONTRACT_ADDRESSES.split(",");
          const readonly721 =
            process.env.VUE_APP_721_READONLY_CONTRACT_ADDRESSES.split(",");
          const readonly1155 =
            process.env.VUE_APP_1155_READONLY_CONTRACT_ADDRESSES.split(",");

          for (let k in available721) {
            if (readonly721.indexOf(available721[k]) === -1) {
              const wl = this.whitelists["wl_" + available721[k].substr(0, 5).toLowerCase()];
              if (wl.indexOf("*") !== -1 || wl.indexOf(app.account) !== -1) {
                app.availableContracts.push({
                  address: available721[k],
                  standard: 721,
                });
              }
            } else {
              console.log("Ignoring " + available721[k] + " because is read-only")
            }
          }
          for (let k in available1155) {
            if (available1155[k].length > 0) {
              // Selecting first 1155 by default
              if (readonly1155.indexOf(available1155[k]) === -1) {
                const wl = this.whitelists["wl_" + available1155[k].substr(0, 5).toLowerCase()];
                if (wl.indexOf("*") !== -1 || wl.indexOf(app.account) !== -1) {
                  if (app.contractAddress.length === 0) {
                    app.contractAddress = available1155[k];
                    app.standardContract = '1155';
                  }
                  app.availableContracts.push({
                    address: available1155[k],
                    standard: 1155,
                  });
                }
              }
            } else {
              console.log("Ignoring " + available721[k] + " because is read-only")
            }
          }
          app.isContractChecked = true;
        } else {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x2AC2",
                chainName: "Quadrans",
                rpcUrls: ["https://rpc.quadrans.io"],
                nativeCurrency: {
                  name: "Quadrans Coin",
                  symbol: "QDC",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://explorer.quadrans.io/"],
              },
            ],
          });
          app.connect();
        }
      } catch (e) {
        console.log("Error while connecting", e)
      }
    },
    async uploadFile() {
      const app = this;
      if (
        app.fileToMint.name.length > 0 &&
        app.name.length > 0 &&
        (app.standardContract.toString() === "721" ||
          (app.standardContract.toString() === "1155" &&
            parseInt(app.amount) > 0)) &&
        !app.isUploadingIPFS
      ) {
        //chiedere firma di un messaggio al metamask ("Create metadata for  app.fileToMint.name at new Date().getTime()")
        //per mandare il messaggio fare una chiamata:
        app.code =
          "Create metadata for " +
          app.fileToMint.name +
          " at " +
          new Date().getTime();
        try {
          app.signature = await app.web3.eth.personal.sign(
            app.code,
            app.account
          );
          console.log("signature is:", app.signature);
        } catch (e) {
          alert(e.message);
        }
        if (app.account !== undefined && app.signature.length > 0) {
          app.isUploadingIPFS = true;
          const formData = new FormData();
          formData.append("file", app.fileToMint);
          formData.append("description", app.description);
          formData.append("name", app.name);
          formData.append("message", app.code);
          formData.append("signature", app.signature);
          app.printLog("name:", app.name);
          app.printLog("description:", app.description);
          app.printLog("Sending request...");
          //come append mettere nome desrizione
          try {
            let response = await axios({
              method: "post",
              url: process.env.VUE_APP_API_URL + "/upload",
              data: formData,
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + process.env.VUE_APP_UPLOAD_KEY,
              },
            });
            app.ipfsFile = response.data.ipfsHash;
            app.isUploadingIPFS = false;
            // file di risposta è già pronto per il minting (app.ipfsMetadata)
            app.ipfsMetadata = true;
          } catch (e) {
            alert("Can't create metadata");
          }
          app.printLog("All data was submitted");
        } else {
          alert("Sign message first!");
        }
      } else {
        alert("Fill all required fields!");
      }
    },
    async mint() {
      const app = this;
      console.log(app.standardContract);
      if (!app.isMinting) {
        if (app.standardContract.toString() === "1155") {
          app.isPrepareMinting = true;
          try {
            app.contract = await new app.web3.eth.Contract(
              ABI_1155,
              app.contractAddress,
              {
                gasLimit: "500000",
              }
            );
            app.printLog("Hash to mint is: " + app.ipfsFile);
            let exists = await app.contract.methods
              ._metadataToId(app.ipfsFile)
              .call();
            app.printLog("Is the file already existing? " + exists);
            if (parseInt(exists) === 0) {
              app.printLog("Preparing nft on-chain first..")
              if (app.ipfsFile.length > 0) {
                let prepared = await app.contract.methods
                  .prepare(app.ipfsFile)
                  .send({ from: this.account })
                  .on("transactionHash", (tx) => {
                    app.printLog(
                      "Waiting for transaction to be confirmed at: " + tx
                    );
                  });
                app.printLog(
                  "Successfully prepared at: " + prepared.transactionHash
                );
                app.isPrepareMinting = false;
                app.isMinting = true;
                let minted = await app.contract.methods
                  .mint(app.ipfsFile, app.amount)
                  .send({ from: this.account })
                  .on("transactionHash", (tx) => {
                    app.printLog(
                      "Waiting for transaction to be confirmed at: " + tx
                    );
                  });
                app.printLog(
                  "Successfully minted at: " + minted.transactionHash
                );
                app.isMinting = false;
                app.name = "";
                app.description = "";
                app.amount = 0;
                app.ipfsFile = "";
                app.ipfsMetadata = "";
                app.fileToMint = {};
              }
            } else {
              app.printLog("This NFT exists yet!");
              app.isPrepareMinting = false;
              app.ipfsFile = "";
              app.ipfsMetadata = "";
              app.fileToMint = {};
              app.name = "";
              app.description = "";
            }
          } catch (e) {
            app.printLog(e.message);
          }
        } else {
          app.isMinting = true;
          console.log("IM ON 721");
          try {
            app.contract = await new app.web3.eth.Contract(
              ABI_721,
              app.contractAddress,
              {
                gasLimit: "5000000",
              }
            );
            let minted = await app.contract.methods
              .mintNFT(app.account, app.ipfsFile)
              .send({ from: this.account })
              .on("transactionHash", (tx) => {
                app.printLog(
                  "Waiting for transaction to be confirmed at: " + tx
                );
              });
            app.printLog("Successfully minted at: " + minted.transactionHash);
            app.isMinting = false;
            app.name = "";
            app.description = "";
            app.amount = 0;
            app.ipfsFile = "";
            app.ipfsMetadata = "";
            app.fileToMint = {};
          } catch (e) {
            app.printLog(e.message);
          }
        }
      }
    },
    changeContract() {
      localStorage.clear();
      location.href = "/";
    },
    scrollToEnd() {
      var container = document.querySelector(".fake-window-body");
      var scrollHeight = container.scrollHeight;
      container.scrollTop = scrollHeight;
    },
    mounted() {
      this.scrollToEnd();
    },
    updated() {
      this.scrollToEnd();
    },
  },
};
</script>

<style scoped>
#printLog {
  word-break: break-all;
}

.fake-browser {
  /* width: 860px; */
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0px 10px #ccc;
}

.fake-browser .fake-browser-header {
  background-color: #eee;
  height: 44px;
  display: flex;
  align-items: center;
}

.fake-browser .fake-browser-header .action-btns {
  min-width: 100px;
}

.fake-browser .fake-browser-header .action-btns span {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: 10px;
  border-radius: 50%;
}

.fake-browser .fake-browser-header .action-btns span:first-child {
  background-color: red;
}

.fake-browser .fake-browser-header .action-btns span:nth-child(2) {
  background-color: orange;
}

.fake-browser .fake-browser-header .action-btns span:nth-child(3) {
  background-color: green;
}

.fake-browser .fake-browser-header .address-bar {
  background-color: white;
  padding: 4px 10px;
  border-radius: 4px;
  flex: 1;
}

.fake-browser .fake-browser-header .address-bar input {
  width: 100%;
  border: none;
  padding: 0;
  outline: none;
  font-size: 12px;
}

.fake-browser .fake-browser-header .setting-more {
  min-width: 100px;
  text-align: right;
  padding-right: 15px;
}

.fake-browser .fake-browser-header .setting-more .more-btn {
  position: relative;
}

.fake-browser .fake-browser-header .setting-more .more-btn,
.fake-browser .fake-browser-header .setting-more .more-btn:before,
.fake-browser .fake-browser-header .setting-more .more-btn:after {
  display: inline-block;
  height: 3px;
  width: 20px;
  border-radius: 2px;
  background-color: #999;
  vertical-align: middle;
}

.fake-browser .fake-browser-header .setting-more .more-btn:before,
.fake-browser .fake-browser-header .setting-more .more-btn:after {
  content: "";
  position: absolute;
  left: 0;
}

.fake-browser .fake-browser-header .setting-more .more-btn:before {
  top: -6px;
}

.fake-browser .fake-browser-header .setting-more .more-btn:after {
  bottom: -6px;
}

.fake-browser .fake-window-body {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #262951 !important;
}

.fake-browser .fake-window-body iframe {
  width: 100%;
  height: 100%;
}

.blinking-cursor {
  content: "";
  /* Remove display: inline-block if not required to be on the same line as text etc */
  display: inline-block;
  background-color: #d6d6d6;
  vertical-align: top;
  margin-top: 5px;
  width: 8px;
  height: 15px;
  margin-left: 5px;
  -webkit-animation: blink 1s step-end infinite;
  animation: blink 1s step-end infinite;
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
